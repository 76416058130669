/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
html {
  width: 100vw;
}

body {
  width: 98%;
  font-family: var(--font-family);
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 0.7rem;
  }
}